import React, { useEffect, useState, useRef } from 'react';
import { Picker } from '@Types/product/Picker';
import { Variant } from '@Types/product/Variant';
import { PICKERS_MAX_COUNT, PICKERS_MIN_COUNT, SIZE_CHART_TO_DISPLAY } from 'helpers/constants/aafes';
import { useFormat } from 'helpers/hooks/useFormat';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { tablet, mobile } from 'helpers/utils/screensizes';
import { SelectedPicker } from '.';
import SizeChart from '../../Modals/sizeChartModal';

type Props = {
  availablePickers: Picker[];
  availableSwatch: Record<string, string>;
  variant: Variant;
  variants: Variant[];
  onChangeVariantIdx: (index: number) => void;
  selectedPicker: SelectedPicker;
  setSelectedPicker: React.Dispatch<React.SetStateAction<SelectedPicker>>;
};

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}
const VariantPicker = ({
  availablePickers,
  availableSwatch,
  variant,
  variants,
  onChangeVariantIdx,
  selectedPicker,
  setSelectedPicker,
}: Props) => {
  const { formatMessage } = useFormat({ name: 'common' });
  const pickerContainerRef = useRef<HTMLDivElement[]>([]);
  const [isDesktop] = useMediaQuery(tablet);
  const colorPicker = 'Color';
  const sizePicker = 'Size';
  const [isShowSizeChart, setIsShowSizeChart] = useState(false);
  const [isPickerSizeChart, setIsPickerSizeChart] = useState<boolean>(true);
  const [maxCount, setMaxCount] = useState<number>(isDesktop ? PICKERS_MAX_COUNT : PICKERS_MIN_COUNT);
  const [maxCountToShow, setMaxCountToShow] = useState(Array(availablePickers?.length).fill(maxCount));
  const [showExtraLines, setShowExtraLines] = useState([]);

  const findVariantIndex = (option: SelectedPicker): number => {
    const matchingVariant = variants.findIndex((variant) =>
      Object.keys(option).every((key) => variant?.picker?.[key] === option?.[key]),
    );
    return matchingVariant !== -1 ? matchingVariant : -1;
  };

  const isOptionAvailable = (option: SelectedPicker): boolean => {
    const matchingVariant = variants?.find((variant) =>
      Object.keys(option).every((key) => variant?.picker?.[key] === option?.[key] && variant?.isOnStock),
    );
    return !!matchingVariant;
  };

  const handleSectionExpand = (length, i) => {
    if (maxCountToShow?.[i] != maxCount) {
      const updatedQuantity = [...maxCountToShow];
      updatedQuantity[i] = maxCount;
      setMaxCountToShow(updatedQuantity);
    } else {
      const updatedQuantity = [...maxCountToShow];
      updatedQuantity[i] = length;
      setMaxCountToShow(updatedQuantity);
    }
  };

  const handleVariantChange = (pickerName: string, value: string): void => {
    const updatedPickerOptions = { ...selectedPicker, [pickerName]: value };
    setSelectedPicker(updatedPickerOptions);
    const variantIndex = findVariantIndex(updatedPickerOptions);
    if (variantIndex !== -1) {
      onChangeVariantIdx(variantIndex);
    }
  };

  const getClassNamesForPicker = (pickerName: string, value: string): string => {
    const optionAvailable = isOptionAvailable({ ...selectedPicker, [pickerName]: value });
    const isSelectedValue = selectedPicker?.[pickerName] === value;
    if (optionAvailable && isSelectedValue) {
      return 'bg-blue-900 text-sm text-white font-normal border-blue-900';
    } else if (optionAvailable && !isSelectedValue) {
      return pickerName === colorPicker
        ? 'border-gray-400 text-sm text-gray-900 font-normal'
        : 'border-blue-900 text-sm text-gray-900 font-normal';
    } else if (!optionAvailable && isSelectedValue) {
      return pickerName === colorPicker
        ? 'border-dashed border-blue-900 text-sm text-gray-900 font-normal line-through-45deg '
        : 'border-dashed border-blue-900 text-sm text-gray-900 font-normal';
    } else {
      return pickerName === colorPicker
        ? 'border-dashed border-gray-400 text-sm text-gray-900 font-normal line-through-45deg'
        : 'border-dashed border-gray-400 text-sm text-gray-900 font-normal';
    }
  };

  function closeSizeChartModal() {
    setIsShowSizeChart(false);
  }

  const handleSizeChart = () => {
    return (
      <div
        className="w-fit cursor-pointer whitespace-nowrap text-base font-semibold leading-7 text-blue-900 decoration-2 hover:underline"
        onClick={() => setIsShowSizeChart(true)}
      >
        {formatMessage({ id: 'sizeChart', defaultMessage: 'Size Chart' })}
      </div>
    );
  };

  const isShowExpand = (pickerName: string) => {
    const container = pickerContainerRef.current.find((ref) => ref?.querySelector(`#${pickerName}-picker`));
    if (container) {
      const parentContainer = container?.parentElement;
      if (parentContainer) {
        const containerWidth = container?.scrollWidth;
        const parentContainerWidth = parentContainer?.clientWidth;
        const viewPortWidth = Math.max(document?.documentElement?.clientWidth || 0, window?.innerWidth || 0);
        const isMobile = viewPortWidth <= tablet; // Adjust the breakpoint as per design
        const expandThreshold = isMobile ? 0.5 : 0.8; // Adjust the threshold value for mobile and desktop
        return containerWidth >= parentContainerWidth * expandThreshold;
      }
    }
    return false;
  };

  const handleToggleLines = (pickerName) => {
    setShowExtraLines((prevShowExtraLines) => {
      const updatedArray = [...prevShowExtraLines];
      const index = updatedArray?.indexOf(pickerName);

      if (index !== -1) {
        updatedArray.splice(index, 1);
      } else {
        updatedArray.push(pickerName);
      }
      return updatedArray;
    });
  };

  useEffect(() => {
    if (!isDesktop) {
      setMaxCount(PICKERS_MIN_COUNT);
      setMaxCountToShow(Array(availablePickers?.length).fill(PICKERS_MIN_COUNT));
    } else {
      setMaxCount(PICKERS_MAX_COUNT);
      setMaxCountToShow(Array(availablePickers?.length).fill(PICKERS_MAX_COUNT));
    }
  }, [isDesktop]);

  useEffect(() => {
    setSelectedPicker(variant?.picker);
  }, [variant]);

  useEffect(() => {
    const hasMatchingPicker = availablePickers?.some((picker) => picker?.name === SIZE_CHART_TO_DISPLAY);
    if (variant?.attributes?.sizeChartUrl && !hasMatchingPicker) {
      setIsPickerSizeChart(false);
    }
  }, [variant, availablePickers]);

  return (
    <section aria-labelledby="variant-picker" className="my-5">
      {availablePickers?.map((picker: Picker, i: number) => {
        const maxCharLength = Math?.max(...picker?.values?.map((item) => item.length));
        return (
          <section aria-labelledby="pickers" key={i} className="py-2.5">
            <div className="mb-2.5 flex h-auto justify-between text-sm leading-7 text-gray-900">
              <div>
                <label className="py-[7px] font-bold">{picker?.name} :</label>
                <span className="mx-2.5 py-[7px] font-normal" aria-hidden="true">
                  {selectedPicker?.[picker?.name]}
                </span>
              </div>
              {variant?.attributes?.sizeChartUrl && picker?.name === SIZE_CHART_TO_DISPLAY
                ? handleSizeChart()
                : variant?.attributes?.sizeChartUrl && !isPickerSizeChart && i === 0 && handleSizeChart()}
            </div>
            <div className="my-1.5">
              <div className="flex items-start justify-between gap-x-2 gap-y-5">
                <div
                  ref={(element: HTMLDivElement) => pickerContainerRef?.current?.push(element)}
                  id="picker-container"
                  className={`${
                    picker?.name === colorPicker
                      ? `flex w-full grid-cols-3 flex-wrap items-center gap-2.5 gap-x-1.5 overflow-hidden md:grid  md:max-w-[90%] md:grid-cols-5`
                      : `flex flex-wrap items-center gap-2.5 overflow-hidden md:max-w-[90%] ${
                          showExtraLines.includes(picker?.name) ? '' : 'max-h-[36px] overflow-hidden'
                        }`
                  }`}
                >
                  {picker?.values
                    ?.slice(0, picker?.name === colorPicker ? maxCountToShow[i] : picker?.values?.length)
                    ?.map((value: string, j: number) => (
                      <label
                        key={j}
                        title={value}
                        id={`${picker?.name}-picker`}
                        className={classNames(
                          'flex cursor-pointer items-center p-2',
                          picker?.name === colorPicker
                            ? 'h-[42px] w-[42px] rounded-full border-2'
                            : 'flex h-[36px] justify-center whitespace-nowrap rounded border-2',
                          getClassNamesForPicker(picker?.name, value),
                        )}
                        style={
                          picker?.name === colorPicker && availableSwatch?.[value]
                            ? { backgroundImage: `url(${availableSwatch?.[value]})` }
                            : picker?.name !== colorPicker && picker?.name === sizePicker
                            ? { width: `${maxCharLength + 5}ch` }
                            : picker?.name === colorPicker && !availableSwatch?.[value]
                            ? { backgroundColor: value }
                            : {}
                        }
                      >
                        <input
                          type="radio"
                          id={value}
                          name={picker?.name}
                          aria-describedby="selected picker"
                          value={value}
                          checked={selectedPicker?.[picker?.name] === value}
                          onChange={() => handleVariantChange(picker?.name, value)}
                          className="invisible h-0 w-0"
                        />
                        {picker?.name === colorPicker ? (
                          <span aria-hidden="true" className="sr-only">
                            {value}
                          </span>
                        ) : (
                          value
                        )}
                      </label>
                    ))}
                </div>
                {picker?.name === colorPicker
                  ? picker?.values?.length > maxCount && (
                      <div>
                        <button
                          onClick={() => handleSectionExpand(picker?.values?.length, i)}
                          className={`flex h-[36px] w-[40px] items-center justify-center rounded-[3px] border-[1px] border-solid border-[#00000033] `}
                        >
                          {maxCountToShow?.[i] !== maxCount ? (
                            <>
                              <svg
                                width="14"
                                height="14"
                                viewBox="0 0 14 2"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M0 2H14V0H0V2Z" fill="#043C63" />
                              </svg>
                            </>
                          ) : (
                            <>
                              <svg
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M6 14V8H0V6H6V0H8V6H14V8H8V14H6Z" fill="#043C63" />
                              </svg>
                            </>
                          )}
                        </button>
                      </div>
                    )
                  : isShowExpand(picker?.name) && (
                      <div>
                        <button
                          onClick={() => handleToggleLines(picker?.name)}
                          className={`flex h-[36px] w-[40px] items-center justify-center rounded-[3px] border-[1px] border-solid border-[#00000033] `}
                        >
                          {showExtraLines.includes(picker?.name) ? (
                            <>
                              <svg
                                width="14"
                                height="14"
                                viewBox="0 0 14 2"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M0 2H14V0H0V2Z" fill="#043C63" />
                              </svg>
                            </>
                          ) : (
                            <>
                              <svg
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M6 14V8H0V6H6V0H8V6H14V8H8V14H6Z" fill="#043C63" />
                              </svg>
                            </>
                          )}
                        </button>
                      </div>
                    )}
              </div>
            </div>
          </section>
        );
      })}
      {isShowSizeChart && (
        <SizeChart closeModal={closeSizeChartModal} sizeChartURL={variant?.attributes?.sizeChartUrl} />
      )}
    </section>
  );
};

export default VariantPicker;
